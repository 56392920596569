import React, { ChangeEvent, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { IconButton, TextField } from '@mui/material';
import { Clear } from '@mui/icons-material';
import { eq, get } from 'lodash';

import { SciContext } from 'src/context/SciContext';
import { EMPTY_STRING } from 'src/utils/common';
import { theme } from 'src/styles/theme';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: '100%',
    marginTop: '5px',
    paddingRight: '20px',
  },
  hidden: {
    display: 'none !important',
  },
  input: {
    color: theme.palette.primary.main,
  }
})

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {};

export const Search: React.FC<Props> = () => {
  const classes = useStyles();

  const { search, setSearch } = useContext(SciContext);

  return (
    <div className={classes.root}>
      <TextField
        fullWidth
        label="Any..."
        variant="standard"
        value={search}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          setSearch(get(event, 'target.value', EMPTY_STRING)) // @note: DEFAULT_SEARCH_VALUE
        }
        InputProps={{
          endAdornment: (
            <IconButton
              className={eq(search, EMPTY_STRING) ? classes.hidden : void 0}
              size={'small'}
              color={'primary'}
              aria-label={'clear search'}
              onClick={() => setSearch(EMPTY_STRING)}
            >
              <Clear fontSize={'small'} color={'primary'} />
            </IconButton>
          ),
          className: classes.input,
        }}
      />
    </div>
  );
};
