import React, { useContext } from 'react';

import { SciLanguage, SciSection, SciSectionParagraph } from 'src/services/resources';
import { makeStyles } from '@mui/styles';
import { eq, map } from 'lodash';
import Typography from '@mui/material/Typography';
import { Chip, Grid } from '@mui/material';
import { SciContext } from '../../../context/SciContext';
import { EMPTY_BLOCK } from '../../../utils/common';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  section: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '30px',
    // padding: '20px 20px 20px 20px',
  },
  title: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  titleText: {
    fontSize: '23px !important',
    padding: '30px 20px 20px 30px',
  },
  text: {
    textIndent: '30px',
  },
  paragraphList: {

  },
  language: {
    marginTop: '27px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '3px'
  }
});

type Props = {
  content: SciSection[]
};

export const SciContent: React.FC<Props> = ({ content }) => {
  const classes = useStyles();

  const { setSciLanguage, sciLanguage } = useContext(SciContext);

  return (
    <div className={classes.root}>
      <Grid container>
        {map(content, ({ title, paragraph }, key: number) => {
          return (
            <>
              <Grid item xs={0} md={2} />
              <Grid item xs={12} md={8}>
                <div className={classes.section}>
                  <div className={classes.title}>
                    <Typography variant="h3" gutterBottom className={classes.titleText}>
                      {title}
                    </Typography>
                    {
                      eq(key, 0)
                        ? (
                          <div className={classes.language}>
                            <Chip
                              label="En"
                              variant={eq(sciLanguage, SciLanguage.En) ? 'filled' : 'outlined'}
                              onClick={() => setSciLanguage(SciLanguage.En)}
                            />
                            <Chip
                              label="Ru"
                              variant={eq(sciLanguage, SciLanguage.Ru) ? 'filled' : 'outlined'}
                              onClick={() => setSciLanguage(SciLanguage.Ru)}
                            />
                          </div>
                        )
                        : EMPTY_BLOCK
                    }
                  </div>
                  <div className={classes.paragraphList}>
                    {map(paragraph, (text: SciSectionParagraph, paragraphKey: number) => {
                      return (
                        <div className={classes.text}>
                          {text}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Grid>
              <Grid item xs={0} md={2} />
            </>
          );
        })}
      </Grid>
    </div>
  );
}