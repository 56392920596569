import React, { useContext, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { eq, find, get, isNull, isUndefined, map, orderBy, replace, size } from 'lodash';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

import { Maybe, Nullable } from 'src/types';
import { SciContext } from 'src/context/SciContext';
import { ModalContext } from 'src/context/ModalContext';
import { Sci, Uid } from 'src/services/resources';
import { EMPTY_ARRAY, EMPTY_BLOCK, EMPTY_STRING, HASH } from 'src/utils/common';
import { localeDate } from 'src/utils/date';

import { SciContentSection } from './sci-content-section';

import { theme } from 'src/styles/theme';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    paddingTop: '105px',
    flexDirection: 'column',
    width: '100%',
    position: 'absolute',
  },
  accordion: {
    width: '100%',
  },
  accordionExpanded: {
    // margin: '0 auto !important',
  },
  accordionSummaryExpanded: {
    borderBottom: `1px solid ${theme.palette.primary.main} !important`,
  },
  actionList: {
    position: 'absolute',
    top: '5px',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      marginLeft: '-10px',
      top: 0,
    },
  },
  dateSection: {
    height: '21px',
    [theme.breakpoints.down('sm')]: {
      height: '20px',
    },
  },
  dateValue: {
    marginTop: '-3px !important',
  },
  emptySection: {
    textAlign: 'center',
    marginLeft: '10px !important',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
  },
  summaryContainer: {
    paddingLeft: '4px',
  },
})

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {};

export const SciSection: React.FC<Props> = () => {
  const classes = useStyles();

  const { list, isExpanded, expand, sciLanguage } = useContext(SciContext);
  const { setBody, handleOpen } = useContext(ModalContext);

  const accordionRef = useRef<Nullable<HTMLDivElement>[]>(EMPTY_ARRAY);

  function sortList(list: Sci[]) {
    return orderBy(list, [({ date }: Sci) => date], 'desc');
  }

  const emptySection = (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant={'body2'} className={classes.emptySection}>
          Empty...
        </Typography>
      </Grid>
    </Grid>
  );

  function getHashUid(): Maybe<Uid> {
    const hash = window.location.hash;
    return !isUndefined(hash)
      ? get(find(list, ({ code }: Sci) => eq(code, replace(hash, HASH, EMPTY_STRING))), 'uid', void 0)
      : void 0;
  }

  useEffect(() => {
    const hashUid = getHashUid();
    if (!isUndefined(hashUid)) {
      const key = sortList(list).findIndex(({ uid }: Sci) => eq(uid, hashUid));
      if (!isUndefined(key) && !isNull(key)) {
        const element = accordionRef.current[key];
        if (!isNull(element)) {
          const scrollTop = element.offsetTop;
          window.scrollTo({
            top: scrollTop - 100,
          });
        }
      }
    }
  }, []);

  return (
    <div className={classes.root}>
      {eq(size(list), 0) ? emptySection : EMPTY_BLOCK }
      {map(sortList(list), ({ uid, title, date, type, content, code }: Sci, key: number) => {
        const hashUid = getHashUid();
        return (
          <Accordion
            disableGutters
            ref={(element: Nullable<HTMLDivElement>) => accordionRef.current[key] = element}
            defaultExpanded={!isUndefined(hashUid) && eq(uid, hashUid)}
            expanded={isExpanded[uid]}
            onChange={(event: React.SyntheticEvent, _: boolean) => {
              if (!eq(get(event, 'target.innerText', void 0), HASH)) {
                expand(uid);
              }
            }}
            className={classes.accordion}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              id={uid}
            >
              <Grid
                container
                className={classes.summaryContainer}
              >
                <Grid item xs={12} sm={6}>
                  <div className={classes.title}>
                    <Typography sx={{ color: 'text.primary' }} variant={'h3'}>
                      {title}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.dateSection}>
                  <Typography
                    sx={{ color: 'text.secondary' }}
                    variant={'body2'}
                    className={classes.dateValue}
                  >
                    {localeDate(date)}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <SciContentSection
                type={type}
                title={title}
                date={date}
                content={content[sciLanguage]}
                code={code}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}