import React, { Context, createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compact, concat, get, isUndefined, join, lowerCase, map, merge } from 'lodash';

import { EMPTY_STRING, SPACE } from 'src/utils/common';
import { Sci, SCI_LIST, SciLanguage, Uid } from '../services/resources';

export const DEFAULT_SOUND_YEAR = 2021;

export type Expand = { [uid: Uid]: boolean };

type SciContextValue = {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  list: Sci[];
  search: string;
  setSearch: (search: string) => void;
  year: number;
  setYear: (year: number) => void;
  isExpanded: Expand,
  expand: (code: Uid) => void;
  setExpanded: (expand: Expand) => void;
  sciLanguage: SciLanguage;
  setSciLanguage: (sciLanguage: SciLanguage) => void;
};

export const SciContext: Context<SciContextValue> =
  createContext<SciContextValue>({
    loading: false,
    setLoading: (loading: boolean) => void 0,
    list: [],
    search: EMPTY_STRING,
    setSearch: (search: string) => void 0,
    year: DEFAULT_SOUND_YEAR,
    setYear: (year: number) => void 0,
    isExpanded: {},
    expand: (code: Uid) => void 0,
    setExpanded: (expand: Expand) => void 0,
    sciLanguage: SciLanguage.En,
    setSciLanguage: (sciLanguage: SciLanguage) => void 0,
  });

export const SciProvider: React.FC<any> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>(EMPTY_STRING);
  const [list, setList] = useState<Sci[]>(SCI_LIST);
  const [year, setYear] = useState<number>(DEFAULT_SOUND_YEAR);
  const [isExpanded, setExpanded] = useState<Expand>({});
  const [sciLanguage, setSciLanguage] = useState<SciLanguage>(SciLanguage.En);

  function expand(code: Uid) {
    setExpanded(merge(isExpanded, {
      [code]: !get(isExpanded, 'code', false),
    }));
    // setExpanded({
    //   [code]: !get(isExpanded, 'code', false),
    // });
  }

  useEffect(() => {
    setExpanded({});
    const sciList = map(SCI_LIST, (sci: Sci, key: number) => {
      const text = join(
        map(
          get(sci, `content[${sciLanguage}]`, void 0), ({ paragraph }) => {
            return paragraph;
          }
        ), EMPTY_STRING);
      const contentValue = join(
        concat(
          get(sci, 'title', void 0),
          text
        ),
        SPACE
      );
      const searchPosition = contentValue
        .search(new RegExp(`${lowerCase(search)}`, 'i'));
      return searchPosition === -1 ? void 0 : sci;
    });
    setList(compact(sciList));
  }, [search]);

  useEffect(() => {
    setList(SCI_LIST);
  }, []);

  return (
    <SciContext.Provider value={{
      loading,
      setLoading,
      list,
      search,
      year,
      setYear,
      setSearch,
      expand,
      isExpanded,
      setExpanded,
      setSciLanguage,
      sciLanguage
    }}>
      {children}
    </SciContext.Provider>
  );
};

SciProvider.propTypes = {
  children: PropTypes.oneOf([
    PropTypes.any,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};
