import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, IconButton, Slide, Snackbar, Tooltip, Typography } from '@mui/material';
import { Link, Telegram } from '@mui/icons-material';
import { AUTHOR, ContentType, SciSection } from 'src/services/resources';
import { theme } from 'src/styles/theme';
import { composeSciLink } from 'src/utils/content';
import { copyUrl, openUrl } from 'src/utils/window';

import { SciItem } from '../sci-item';
import { SciContext } from '../../../context/SciContext';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    height: '100%',
    overflowY: 'auto',
  },
  title: {
    paddingLeft: '70px',
  },
  stanzaList: {

  },
  stanza: {
    paddingBottom: '20px',
    '&:last-child': {
      paddingBottom: '0',
    },
  },
  stanzaRow: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px !important',
    },
  },
  epilogue: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    maxWidth: '100%',
  },
  shareTelegram: {
    marginTop: "-4px !important",
    marginLeft: "4px !important",
  },
  shareLink: {
    marginTop: '-4px !important',
  },
  divider: {
    maxWidth: '100%',
    marginLeft: '0',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: '0',
    },
  }
});

type Props = {
  type: ContentType | ContentType[],
  title: string;
  date: Date;
  code: string;
  content: SciSection[];
};

export const SciContentSection: React.FC<Props> = ({
  type,
  title,
  date,
  content,
  code,
}) => {
  const classes = useStyles();

  const [copyOpen, setCopyOpen] = useState(false);

  return (
    <div className={classes.root}>
      <Grid
        container
        className={classes.container}
      >
        <Grid item xs={12}>
          <Grid container className={classes.stanzaList}>
            <SciItem
              type={type}
              title={title}
              content={content}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <hr className={classes.divider} />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.epilogue}>
            <div>
              <Typography variant={'body1'} sx={{ color: 'text.secondary' }}>
                {AUTHOR}, {date.getFullYear()}
              </Typography>
            </div>
            <div>
              <Tooltip
                title={"Share"}
                arrow
              >
                <IconButton
                  className={classes.shareTelegram}
                  onClick={() => {
                    openUrl(`https://t.me/share/url?url=${encodeURIComponent(composeSciLink(code))}&text=${`${title}${encodeURIComponent('\n')}Etc.`}`);
                  }}
                  aria-label={title}
                >
                  <Telegram />
                </IconButton>
              </Tooltip>
            </div>
            <div>
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={copyOpen}
                ContentProps={{
                  sx: {
                    display: 'block',
                    textAlign: "center"
                  }
                }}
                TransitionComponent={(props: any) =>
                  <Slide {...props} direction="down" />}
                message={'Article link is copied...'}
                key={code}
              />
              <Tooltip
                title={copyOpen ? "Copied" : "Article link"}
                arrow
              >
                <IconButton
                  className={classes.shareLink}
                  onClick={() => {
                    copyUrl(composeSciLink(code));
                    setCopyOpen(true);
                    setTimeout(() => {
                      setCopyOpen(false);
                    }, 2000);
                  }}
                  aria-label={'copy'}
                >
                  <Link />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}