import React from 'react';

import { SciProvider } from 'src/context/SciContext';
import { ModalProvider } from 'src/context/ModalContext';

import { Header } from './header';
import { Layout } from './layout';

import { SciSection } from '../sci';

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {};

export const Application: React.FC<Props> = () => {
  return (
    <ModalProvider>
      <SciProvider>
        <Layout>
          <Header />
          <SciSection />
        </Layout>
      </SciProvider>
    </ModalProvider>
  );
}