import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { AppBar, Box, Tab, Tabs, Typography } from "@mui/material";
import { flatten, get, includes, isArray } from "lodash";

import { Maybe } from 'src/types';
import { ContentType, SciSection } from 'src/services/resources';
import { EMPTY_BLOCK } from 'src/utils/common';
import { SciContent } from '../sci-content';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  item: {
    fontSize: '10px',
    color: '#cccccc',
    lineBreak: 'anywhere',
    wordBreak: 'normal',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontFamily: 'Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif',
    fontWeight: 100,
  },
  itemLink: {
    color: '#cccccc',
    textDecoration: 'none',
  },
  appleFrame: {
    width: '100%',
    overflow: 'hidden',
    background: 'transparent',
  },
  tab: {
    backgroundColor: '#fff !important',
    fontFamily: 'Arial !important',
    letterSpacing: 0,
  }
});

type Props = {
  type: ContentType | ContentType[],
  title: string;
  content: SciSection[],
};

export const SciItem: React.FC<Props> = ({
  type,
  title,
  content
}) => {
  const classes = useStyles();

  const [tabIndex, setTabIndex] = useState(0);

  function a11yProps(index: number) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  const onTabIndexChange = (event: React.SyntheticEvent, newIndex: number) => {
    setTabIndex(newIndex);
  };

  const currentType = isArray(type) ? type : flatten([type]);

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={tabIndex}
          onChange={onTabIndexChange}
          indicatorColor="primary"
          variant="fullWidth"
          aria-label={`${title}`}
        >
          <Tab className={classes.tab} label="Science" {...a11yProps(0)} />
        </Tabs>
      </AppBar>
      <>
        <TabPanel value={tabIndex} index={0}>
          <SciContent content={content}/>
        </TabPanel>
      </>
    </div>
  );
}

type TabPanelProps = {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}


function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}